import React, { FunctionComponent, cloneElement } from 'react';

import Trans from '@components/Trans';
import Mark from '@components/ui/Mark';

import useTranslation from '@hooks/useTranslation';

import styles from './PromoTestimonial.module.css';
import imgAVIF from './assets/dawid_jones@1x.avif';
import imgPNG from './assets/dawid_jones@1x.png';
import imgAVIF2x from './assets/dawid_jones@2x.avif';
import imgPNG2x from './assets/dawid_jones@2x.png';

const Content: FunctionComponent = () => {
    const { t } = useTranslation('pages/features/email-marketing/ai-email-generator/index');

    return (
        <div className={styles.testimonial}>
            <div className={styles.details}>
                <h2 className={styles.title}>{t('promoTitle')}</h2>
                <p className={styles.text}>
                    <Trans
                        i18nKey={'promoSubHeader'}
                        components={{
                            mark: <Mark color="navy" />,
                        }}
                        ns="pages/features/email-marketing/ai-email-generator/index"
                    />
                </p>
                <p className={styles.sub}>{t('promoDesc')}</p>
            </div>
            <div className={styles.image}>
                <picture>
                    <source
                        type="image/avif"
                        srcSet={`${imgAVIF.src} 1x, ${imgAVIF2x.src} 2x`}
                    />
                    <source srcSet={`${imgPNG.src} 1x, ${imgPNG2x.src} 2x`} />
                    <img
                        width={imgPNG.width}
                        height={imgPNG.height}
                        decoding="async"
                        loading="lazy"
                        alt={t('promoTitle')}
                        src={imgPNG.src}
                    />
                </picture>
                <blockquote className={styles.blockquote}>
                    <p className={styles.cite}>{t('promoQuote')}</p>
                    <footer className={styles.author}>
                        <p>
                            <Trans
                                i18nKey={'promoQuoteAuthor'}
                                components={{
                                    strong: <strong />,
                                }}
                                ns="pages/features/email-marketing/ai-email-generator/index"
                            />
                        </p>
                    </footer>
                </blockquote>
            </div>
        </div>
    );
};

interface PromoTestimonialProps {
    container: React.ReactElement;
}

export default function PromoTestimonial({ container }: PromoTestimonialProps): JSX.Element {
    return (
        <section className={styles['promo-quote']}>
            {cloneElement(container, {
                children: <Content />,
                maxWidth: '1140px',
            })}
        </section>
    );
}
