import React, { cloneElement, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import Trans from '@components/Trans';
import FeatureInfo from '@components/global/FeatureInfo';
import Heading from '@components/global/Heading';
import { CTAStartFreeCreateAccount } from '@components/ui/CTAStartFreeCreateAccount';
import Mark from '@components/ui/Mark';
import { More } from '@components/ui/More';

import useTranslation from '@hooks/useTranslation';
import { laravelRouteMaxBookADemo } from '@static_components/laravelLinks';

import styles from './Hero.module.css';
import { ReactComponent as SVGLogo } from './assets/logo.svg';

interface InnerContentProps {
    version?: string;
    pageinfo?: string;
}
const AnimEn = dynamic(() => import('./assets/lottieEn'), {
    ssr: false,
});
const AnimPl = dynamic(() => import('./assets/lottiePl'), {
    ssr: false,
});
const AnimDe = dynamic(() => import('./assets/lottieDe'), {
    ssr: false,
});
const AnimIt = dynamic(() => import('./assets/lottieIt'), {
    ssr: false,
});
const AnimEs = dynamic(() => import('./assets/lottieEs'), {
    ssr: false,
});
const AnimPt = dynamic(() => import('./assets/lottiePt'), {
    ssr: false,
});

const Content = ({ version, pageinfo }: InnerContentProps) => {
    const { t } = useTranslation('pages/features/email-marketing/ai-email-generator/index');
    const { locale } = useRouter();

    const [isRes2dppx, setIsRes2dppx] = useState(true);

    useEffect(() => {
        let mql = window.matchMedia('(min-resolution: 2dppx)');

        const handleChange = (e: MediaQueryListEvent | MediaQueryList) => {
            if (e.matches) {
                setIsRes2dppx(true);
            } else {
                setIsRes2dppx(false);
            }
        };
        handleChange(mql);
        mql.addEventListener('change', handleChange);
        return () => {
            mql.removeEventListener('change', handleChange);
        };
    }, []);

    const inViewOptions = {
        threshold: 0.5,
        triggerOnce: false,
    };

    const { ref: inViewRef1, inView: inView1 } = useInView(inViewOptions);

    return (
        <div className={styles.split}>
            <article className={styles.desc}>
                <Heading variant="h1">
                    <Trans
                        i18nKey="mainTitle"
                        components={{
                            mark: <Mark />,
                        }}
                        ns="pages/features/email-marketing/ai-email-generator/index"
                    />
                </Heading>
                <Heading variant="h2">
                    <Trans
                        i18nKey="mainMotto"
                        components={{
                            br: <br />,
                        }}
                        ns="pages/features/email-marketing/ai-email-generator/index"
                    />
                </Heading>
                <p>
                    <Trans
                        i18nKey="mainText"
                        components={{
                            br: <br />,
                        }}
                        ns="pages/features/email-marketing/ai-email-generator/index"
                    />
                </p>
                <FeatureInfo
                    icon={
                        <SVGLogo
                            width={250}
                            height={250}
                        />
                    }
                    text={t('mainInfo')}
                    className={styles.info}
                />
                <div className={styles.btnwrap}>
                    <CTAStartFreeCreateAccount
                        params={{ version: version, pageinfo: pageinfo }}
                        label={t('mainBtn')}
                        size="medium"
                        className={styles.button}
                    />
                </div>
                <More
                    size={'big'}
                    href={laravelRouteMaxBookADemo(locale)}
                    label={t('mainLink')}
                />
            </article>
            <div
                className={styles.picture}
                ref={inViewRef1}
            >
                {locale === 'pl' ? (
                    <AnimPl
                        inView={inView1}
                        isRes2dppx={isRes2dppx}
                    />
                ) : locale === 'de' ? (
                    <AnimDe
                        inView={inView1}
                        isRes2dppx={isRes2dppx}
                    />
                ) : locale === 'it' ? (
                    <AnimIt
                        inView={inView1}
                        isRes2dppx={isRes2dppx}
                    />
                ) : locale === 'es' ? (
                    <AnimEs
                        inView={inView1}
                        isRes2dppx={isRes2dppx}
                    />
                ) : locale === 'pt' ? (
                    <AnimPt
                        inView={inView1}
                        isRes2dppx={isRes2dppx}
                    />
                ) : (
                    <AnimEn
                        inView={inView1}
                        isRes2dppx={isRes2dppx}
                    />
                )}
            </div>
        </div>
    );
};
interface HeroProps extends InnerContentProps {
    container: React.ReactElement;
}

export default function Hero({ container, version, pageinfo }: HeroProps): JSX.Element {
    return (
        <section
            id="top"
            className={styles.hero}
        >
            {cloneElement(container, {
                children: (
                    <Content
                        version={version}
                        pageinfo={pageinfo}
                    />
                ),
                maxWidth: '1140px',
            })}
        </section>
    );
}
